
import { Component } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';
import E404Img from '@/assets/404.svg';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';

@Component({
  components: { PwrBtn }
})
export default class E404View extends PwrVue {
  get img() {
    return E404Img;
  }
}
